import { IDpsAuctionDonor } from "./DpsAuction";

export const DONORS: IDpsAuctionDonor[] = [
  {
    name: 'US Ghost Adventures',
    link: 'https://usghostadventures.com/',
    imgSrc: 'usga.jpeg'
  },
  {
    name: 'Malibu Wine Hikes',
    link: 'https://www.malibuwinehikes.com/',
    imgSrc: 'malibu-wine.jpeg'
  },
  {
    name: 'Hang Smart',
    link: 'https://hangsmart.com/',
    imgSrc: 'hang-smart.webp?bg=black'
  },
  {
    name: 'The Huntington',
    link: 'https://huntington.org/',
    imgSrc: 'huntington.svg'
  },
  {
    name: 'Aqui',
    link: 'https://www.aquicalmex.com/',
    imgSrc: 'aqui.png'
  },
  {
    name: 'Winchester Mystery House',
    link: 'https://winchestermysteryhouse.com/',
    imgSrc: 'winchester.webp'
  },
  {
    name: 'Santa Barbara Zoo',
    link: 'https://www.sbzoo.org/',
    imgSrc: 'sb-zoo.webp'
  },
  {
    name: 'The Walt Disney Family Museum',
    link: 'https://www.waltdisney.org/',
    imgSrc: 'wdfm.svg'
  },
  {
    name: 'Oakland Zoo',
    link: 'https://www.oaklandzoo.org/',
    imgSrc: 'oakland-zoo.svg'
  },
  {
    name: 'The Escape Game',
    link: 'https://theescapegame.com/',
    imgSrc: 'escape-game.svg'
  },
  {
    name: 'Dream Inn',
    link: 'https://www.dreaminnsantacruz.com/',
    imgSrc: 'dream-inn.png?bg=black'
  },
  {
    name: 'Gourmet Gift Baskets',
    link: 'https://www.gourmetgiftbaskets.com/',
    imgSrc: 'gourmet-gift-baskets.webp'
  },
  {
    name: '100% Pure',
    link: 'https://www.100percentpure.com/index.html',
    imgSrc: '100percent-pure.jpg'
  },
  {
    name: 'Dolphin Bay',
    link: 'https://www.thedolphinbay.com/',
    imgSrc: 'dolphin-bay.pdf'
  },
  {
    name: 'Wushu Central',
    link: 'https://wushucentral.com/',
    imgSrc: 'wushu-central.png'
  }/*,
  {
    name: '',
    link: '',
    imgSrc: ''
  }*/
];
